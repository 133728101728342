<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar|uppercase }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-img
        v-if="formattedItem.valor_firma != null"
        :src="`data:image/jpeg;base64,${formattedItem.valor_firma}`"
        contain
        max-height="400"
        max-width="400"
      />
    </v-list-item-content>
    <b10-list-item-right-avatar
      v-if="!tieneValor"
      :color="formattedItem.tdato_requerido ? 'error' : 'warning'"
    >
      sin valor
    </b10-list-item-right-avatar>
  </b10-list-item>
</template>

<script>
import { stringToHslColor } from '@/utils/ui'
import _ from '@/utils/lodash'
import { calculateMd5 } from '@/utils/crypto'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.dato.title(item)
      item.subtitle = this.$online.dato.subtitle(item)
      if (item.tgrupo_tdato_alias) {
        item.avatar = this.$options.filters.initials(item.tgrupo_tdato_alias)
      } else {
        item.avatar = '?'
      }
      item.avatarColor = stringToHslColor(calculateMd5(item.tgrupo_tdato_alias || ''))
      return item
    },
    tieneValor () {
      return this.item.valor != null ||
        this.item.valor_numerico != null ||
        this.item.valor_fecha != null ||
        this.item.valor_firma != null
    }
  },
}
</script>
